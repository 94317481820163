<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Branches</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<b-button v-b-modal.branch-modal variant="primary" class="btn-sm">Add Branch</b-button>
						</div>
					</template>
					<template v-slot:body>
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchBranches()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchBranches()"/>
									</label>
								</div>							
								
							</div>
						</div>
						<!----- Listing length and search ends ----->

						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th width="10%">Branch Name</th>			
										<th width="15%">Created on</th>
										<th width="10%">Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(branches.length)">
									<tr v-for="branch in branches" :key="branch.id">
										<td>{{ branch.branch }}</td>
										<td>{{ branch.created_at | formatDateTime }}</td>
										<td>
											<b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="editBranch(branch)">Edit</b-button>

											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deactivateBranch(branch)" v-if="branch.status == 1">Unpublish</b-button>
											<b-button variant="success" class="btn mt-2 mr-1 btn-sm" @click="activateBranch(branch)" v-else>Publish</b-button>
											
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3" class="text-center">No data found</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="branch-modal" id="branch-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addBranch">
								<b-col md="12" class="form-group">
									<label class="control-label">Branch Name<span class="required">*</span></label>
									<b-form-input v-model="branch.branch_name" v-bind:class="{ 'is-invalid': Boolean(errors.branch_name) }"></b-form-input>
									<div v-for="(val, index) in errors.branch_name" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
																
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('branch-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchBranches(page.url + '&limit=' + listing_length + '&keyword=' + keyword)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "Branches",
	data() {
		return {
			branches: [],
			branch: {
				id: null,
			   	branch_name: "",			   
			},
			
			modal_title: "Add Branch",
			button_title: "Save",
			pagination: {},
			edit: false,
			branch_status: {
				id: "",
				status: "",
			},
			keyword: "",
			showsearch: false,
			listing_length: 10,
			status: '',
			token: "",
			errors: {},
			activate: false,
         	deactivate: false,

         	active_page_url: null,
		};
	},
	mounted() {
			
	},

	created() {
		this.fetchBranches();
	},

		methods: {
		    
		    getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

			fetchBranches(page_url = null) 
			{
				let url = "/api/branches/index?limit=" + this.listing_length + "&keyword=" + this.keyword;
				page_url = page_url || url;
				this.active_page_url = page_url;   
				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.branches = responseData.data;
						this.pagination = responseData.links;
					})
					.catch((err) => console.log(err));
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},
            
            addBranch() {
				this.getToken();
				let formData = new FormData();
				formData.append("branch_name", this.branch.branch_name);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/branches/add";
					this.active_page_url = null;
				} else {
					url = "/api/branches/update";
					formData.append("branch_id", this.branch.id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("branch-modal");
						this.fetchBranches(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);
						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			editBranch(branch) {
			    this.edit = true;
				this.branch.id = branch.id;
				this.branch.branch_name = branch.branch;
				this.errors = {};				
				this.modal_title = "Edit Branch";
				this.button_title = "Update";
				this.showModal("branch-modal");
			},
			clearForm() {								
				this.modal_title = "Add Branch";
				this.button_title = "Add";
				this.edit = false;
				this.branch.branch_name = '';
			},
			
			activateBranch(branch) {
				this.activate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to publish this branch?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		        .then(value => {
		            this.activate = value
		            if(this.activate){
						this.branch_status.id = branch.id;
						this.branch_status.status = "1";
						this.updateCourseStatus();
					}
		        })
		         .catch(err => {
		            console.log(err);
		        })
			},
			deactivateBranch(branch) {
				this.deactivate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to unpublish this branch?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		         .then(value => {
		            this.deactivate = value
		            if(this.deactivate){
						this.branch_status.id = branch.id;
						this.branch_status.status = "0";
						this.updateCourseStatus();
					}
         		})
         		.catch(err => {
           			console.log(err);
         		})
			},
			updateCourseStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/branches/status?branch_id=" + this.branch_status.id + "&status=" + this.branch_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.fetchBranches(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},
		},	
};
</script>
